<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { useTooltip } from "@/composables/useTooltip";
import { injectStrict } from "@/utils/vue";

import DependOnTeam from "../../../ActionMenu/components/DependOnTeamMenuItem/DependOnTeam.vue";
import AttributeChip from "../../../AttributeChip/AttributeChip.vue";
import MirrorStickyChip from "../MirrorStickyChip/MirrorStickyChip.vue";
import StickyLinksChip from "../StickyLinksChip/StickyLinksChip.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);

const precondTeamRef = ref<HTMLSpanElement>();
const dependTeamRef = ref<HTMLSpanElement>();

const dependContent = computed(() => card.dependTeam?.name);
useTooltip({
  el: dependTeamRef,
  tooltip: dependContent,
  aria: { content: null },
});

useTooltip({
  el: precondTeamRef,
  tooltip: ref({
    content: card.precondTeam?.name,
  }),
  aria: { content: null },
});

const { t } = useI18n();

const dependTeam = computed(() => {
  return card.dependTeam
    ? card.dependTeam.name
    : t("actionMenu.dependentOn.unassigned");
});

const triggerAriaLabel = computed(() => {
  return t("actionMenu.dependentOn.ariaLabel", {
    team: dependTeam.value,
  });
});
</script>

<template>
  <div class="dependency-footer">
    <div class="row">
      <StickyLinksChip />
    </div>
    <div class="row">
      <span ref="precondTeamRef" class="team precond">{{
        card.precondTeam?.name
      }}</span>

      <SvgIcon name="arrow/right-thin" class="icon" width="20" height="100%" />
      <span
        v-if="card.dependTeam"
        :ref="(ref) => (dependTeamRef = ref as HTMLSpanElement)"
        class="team depend"
      >
        {{ card.dependTeam.name }}
      </span>
      <AttributeChip
        v-else
        name="depend-on-team-chip"
        :text-placeholder="$t('actionMenu.dependentOn')"
        :tooltip="$t('actionMenu.dependentOn')"
        :readonly="cardMeta.isReadonly"
        :dropdown-props="{ width: 'md' }"
        :trigger-aria-label="triggerAriaLabel"
      >
        <DependOnTeam trigger="attribute-chip" />
      </AttributeChip>

      <SvgIcon
        v-if="card.dependTeam"
        name="arrow/right-thin"
        class="icon"
        width="20"
        height="100%"
      />

      <MirrorStickyChip v-if="card.dependTeam" class="mirror" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/mixins/utils";

.dependency-footer {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    box-sizing: content-box;
    gap: 6px;

    > * {
      flex-shrink: 0;
    }

    .team {
      @include utils.ellipsis;

      max-width: max-content;
      flex: 1 1 40px;
      display: inline-block;

      &.precond {
        flex-shrink: 2;
      }

      &.depend {
        flex-shrink: 1;
      }
    }

    .mirror {
      flex-shrink: 0;
    }

    & > .icon {
      flex-shrink: 0;
    }
  }
}
</style>
