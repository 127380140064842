<script setup lang="ts">
import { computed } from "vue";

import UserAvatar from "@/components/UserAvatar.vue";
import { AuthUser } from "@/model/user";

import Duration from "../Duration";

const props = defineProps<{
  duration: Duration;
  user: AuthUser;
  name: string;
  paused?: boolean;
}>();
const userName = computed(() => props.user.name);
</script>

<template>
  <div class="active-timer">
    <div>
      <div
        v-autofocus
        class="time"
        role="timer"
        :aria-label="
          $t('label.timerPanel.timeRemaining', {
            h: duration.hourStr,
            m: duration.minuteStr,
            s: duration.secondStr,
          })
        "
      >
        <span aria-hidden="true">
          <template v-if="duration.hasHours"
            >{{
              $t("timer.longDuration", {
                h: duration.hourStr,
                m: duration.minuteStr,
                s: duration.secondStr,
              })
            }}
          </template>
          <template v-else
            >{{
              $t("timer.shortDuration", {
                m: duration.minuteStr,
                s: duration.secondStr,
              })
            }}
          </template>
        </span>
      </div>
      <div class="name" :class="{ paused }">
        {{ name }}
      </div>
      <div class="user">
        <UserAvatar :user="user" aria-hidden="true" />
        <span>{{ userName }}</span>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/utils";

.active-timer {
  display: flex;
  align-items: center;

  & > img {
    flex: 0 0 2.5em;
    height: 2em;
    cursor: pointer;
  }

  & > div {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    overflow: hidden;

    .time {
      font-weight: font.$weight-bold;
      color: colors-old.$text-secondary-color;
      margin-bottom: 2px;
      font-size: 14px;
    }

    .name {
      @include utils.ellipsis;

      color: colors-old.$text-secondary-color;
      font-size: 12px;
    }

    .user {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 0.5em;
      color: colors-old.$text-secondary-color;
    }
  }

  .paused {
    color: colors-old.$text-secondary-color;
  }
}
</style>
