<script setup lang="ts">
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/TypeDot.vue";
import { FieldChange } from "@/model/change";
import { useStickyTypeStore } from "@/store/stickyType";

defineProps<{ field: FieldChange<string> }>();

function stickyType(id: string) {
  return useStickyTypeStore().findStickyType(id);
}
</script>

<template>
  <div class="change horizontal">
    <template v-if="field.old">
      <span class="change-value">
        <TypeDot size="medium" :color="stickyType(field.old).color" />
        <span>{{ stickyType(field.old).name }}</span>
      </span>
      <SvgIcon
        name="arrow/right-thin"
        width="20"
        height="20"
        role="img"
        :aria-label="$t('label.activityPanel.changedTo')"
      />
    </template>
    <span class="change-value">
      <TypeDot size="medium" :color="stickyType(field.new!).color" />
      <span> {{ stickyType(field.new!).name }}</span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.type-dot {
  font-size: 200%;
}
</style>
