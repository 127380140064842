import { clamp } from "lodash-es";
import { Ref, onMounted, ref } from "vue";

import { useNativeEvents } from "@/composables/useNativeEvents";
import {
  WindowCoordinate,
  boundsCoord,
  clientCoord,
  minus,
} from "@/model/coordinates";

export function useSimpleDraggable(el: Ref<HTMLElement | undefined>) {
  const { addEventListener } = useNativeEvents();
  onMounted(() => {
    addEventListener(document, "pointerleave", endDrag);
    addEventListener(document, "pointerup", endDrag);
    addEventListener(document, "pointermove", moveDrag);
  });

  const drag = ref<WindowCoordinate | null>(null);

  function startDrag(e: PointerEvent) {
    if (el.value) {
      drag.value = minus(clientCoord(e), boundsCoord(el.value));
      setDragPos(boundsCoord(el.value));
      el.value.style.margin = "0";
    }
  }

  function endDrag() {
    drag.value = null;
  }

  function moveDrag(e: PointerEvent) {
    if (drag.value) {
      setDragPos(minus(clientCoord(e), drag.value));
    }
  }

  function setDragPos(pos: WindowCoordinate) {
    if (el.value) {
      el.value.style.top =
        clamp(pos.y, 0, window.innerHeight - el.value.offsetHeight) + "px";
      el.value.style.left =
        clamp(pos.x, 0, window.innerWidth - el.value.offsetWidth) + "px";
    }
  }

  return { startDrag, endDrag };
}
