import { groupBy } from "lodash-es";

import { BaseStickyInfo } from "@/model/card";
import { useBoardStore } from "@/store/board";
import { useSearchMenuStore } from "@/store/searchMenu";
import { NO_TEAM_ID, useTeamStore } from "@/store/team";
import { i18n } from "@/translations/i18n";

import { groupByTeamName, groupSearchResultsByType } from "./SearchResult";

export function groupResults(
  items: BaseStickyInfo[],
): Record<string, BaseStickyInfo[]> {
  const typeGroupItems = resultsByType(items);

  const searchMenuStore = useSearchMenuStore();
  if (
    !searchMenuStore.isDependencySearch ||
    !searchMenuStore.selectedTeams.length
  ) {
    return typeGroupItems;
  }

  const dependencyStickyTypes = new Set(
    useBoardStore().possibleStickyTypes.flatMap((stickyType) =>
      stickyType.functionality === "dependency" ? stickyType.name : [],
    ),
  );

  const groupResultEntities = Object.entries(typeGroupItems).reduce(
    (groupResultEntities, typeResultGroupEntity) => {
      const [typeName, typeResultGroupItems] = typeResultGroupEntity;
      if (!dependencyStickyTypes.has(typeName)) {
        return [...groupResultEntities, typeResultGroupEntity];
      }

      return [
        ...groupResultEntities,
        ...groupDependencyResults(typeResultGroupItems),
      ];
    },
    new Array<[string, BaseStickyInfo[]]>(),
  );

  return Object.fromEntries(groupResultEntities);
}

function groupDependencyResults(resultGroupItems: BaseStickyInfo[]) {
  const findTeamIndex = (teamName: string) =>
    useTeamStore().teamsInCurrentArt.findIndex(
      (team) => team.name === teamName,
    );

  const redundantTypeResultGroupItems: BaseStickyInfo[] =
    resultGroupItems.flatMap((item) => {
      const dependencyTeamFilter =
        useSearchMenuStore().selectedDependencyTeamFilter;
      const { data: card } = useBoardStore().currentBoard().cards[item.id];
      const duppedItems = [];

      if (
        (dependencyTeamFilter === "Incoming" ||
          dependencyTeamFilter === "IncomingAndOutgoing") &&
        useSearchMenuStore().isTeamSelected(card.precondTeam?.id)
      ) {
        duppedItems.push({
          ...item,
          teamId: card.precondTeam?.id || NO_TEAM_ID,
        });
      }
      if (
        (dependencyTeamFilter === "Outgoing" ||
          dependencyTeamFilter === "IncomingAndOutgoing") &&
        useSearchMenuStore().isTeamSelected(card.dependTeam?.id)
      ) {
        duppedItems.push({
          ...item,
          teamId: card.dependTeam?.id || NO_TEAM_ID,
        });
      }
      if (
        dependencyTeamFilter === "Mutual" &&
        useSearchMenuStore().isTeamSelected(card.precondTeam?.id) &&
        useSearchMenuStore().isTeamSelected(card.dependTeam?.id)
      ) {
        duppedItems.push({
          ...item,
          teamId: card.dependTeam?.id || NO_TEAM_ID,
        });
        duppedItems.push({
          ...item,
          teamId: card.precondTeam?.id || NO_TEAM_ID,
        });
      }

      return duppedItems;
    });

  return Object.entries(groupByTeamName(redundantTypeResultGroupItems)).sort(
    ([a], [b]) => findTeamIndex(a) - findTeamIndex(b),
  );
}

function resultsByType(
  items: BaseStickyInfo[],
): Record<string, BaseStickyInfo[]> {
  return groupSearchResultsByType(items);
}

export function groupSearchResultsByDependencyTeamFilter(
  items: BaseStickyInfo[],
) {
  return groupBy(items, (item) => {
    const card = useBoardStore().currentBoard().cards[item.id];

    if (!card || item.teamId === undefined) {
      return "";
    }

    if (item.teamId === (card.data.precondTeam?.id || NO_TEAM_ID)) {
      return i18n.global.t("dependencyTeamFilter.incoming");
    } else if (item.teamId === (card.data.dependTeam?.id || NO_TEAM_ID)) {
      return i18n.global.t("dependencyTeamFilter.outgoing");
    }
    return "";
  });
}

export function showDependencyGroupingHeader(items: BaseStickyInfo[]) {
  return (
    items.length > 0 &&
    (useBoardStore().currentBoard().type !== "program" ||
      useSearchMenuStore().selectedTeams.length > 0)
  );
}
