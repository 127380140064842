<script setup lang="ts">
import { isString } from "lodash-es";
import { Ref, WatchStopHandle, computed, ref, watch } from "vue";

import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { TooltipProp, useTooltip } from "@/composables/useTooltip";
import { isBacklogBoard } from "@/model/board";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import { useZoomStore } from "@/store/zoom";
import { stickyNoteAttributeChipClicked } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { isDarkColor } from "@/utils/color";
import { injectStrict } from "@/utils/vue";

interface Props {
  as?: "button" | "a";
  text?: string;
  textPlaceholder?: string;
  tooltip?: TooltipProp;
  dropdownProps?: Record<string, unknown>;
  shrink?: boolean; // flex-shrink: 0/1
  active?: boolean;
  readonly?: boolean;
  name?: string; // used for event tracking analytics
  triggerAriaLabel?: string;
}
const props = withDefaults(defineProps<Props>(), {
  as: "button",
  text: undefined,
  textPlaceholder: undefined,
  tooltip: undefined,
  shrink: false,
  dropdownProps: () => ({ stayOpenOnContentClick: true }),
  name: undefined,
  triggerAriaLabel: undefined,
});

const triggerRef = ref<HTMLElement>();
const dropdownRef = ref<typeof DropdownMenu>();

const tooltip = computed(() => props.tooltip);
const tippyRef = useTooltip({
  el: triggerRef,
  tooltip,
  aria: { content: null },
});

const triggerAriaLabel = computed(() => {
  const tooltip = isString(props.tooltip) ? `${props.tooltip}: ` : "";
  const text = props.text || props.textPlaceholder || "";
  return tooltip + text;
});

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const cardMeta = injectStrict(cardMetaKey);
const isCardActive = computed(() => useBoardStore().activeCardId === card.id);

const stickyNoteBackgroundColor = computed(() => {
  if (isBacklogBoard(board.value.type) && card.teamId) {
    return card.type.altColor;
  }
  return card.type.color;
});
const theme = computed(() => {
  return isDarkColor(stickyNoteBackgroundColor.value) ? "dark" : "light";
});

defineOptions({ inheritAttrs: false });

const closeDropdown = () => {
  dropdownRef.value?.close();
};

let zoomingWatcher: WatchStopHandle | undefined;
let boardChangeWatcher: WatchStopHandle | undefined;

const handleOpen = () => {
  if (props.name) {
    const cardType = card.type.functionality;
    const boardType = board.value.type;
    trackEvent(
      stickyNoteAttributeChipClicked(
        props.name,
        cardType,
        boardType,
        isCardActive.value,
      ),
    );
  }

  zoomingWatcher = watch(() => useZoomStore().zooming, closeDropdown);
  boardChangeWatcher = watch(() => useBoardStore().board?.id, closeDropdown, {
    once: true,
  });
};
const handleClose = () => {
  zoomingWatcher?.();
  boardChangeWatcher?.();
};

const dragWatcher: Ref<WatchStopHandle | null> = ref(null);
const stickyWasDragged = ref(false);

const handleDropdownPointerDown = () => {
  tippyRef?.disable();

  dragWatcher.value = watch(
    () => useDraggingStore().dragging[card.id],
    () => (stickyWasDragged.value = true),
  );
};

const handleDropdownClick = () => {
  dragWatcher.value?.();
  tippyRef?.enable();

  if (stickyWasDragged.value) {
    stickyWasDragged.value = false;
    return;
  }
};
</script>

<template>
  <DropdownMenu
    ref="dropdownRef"
    stay-open-on-content-click
    :disabled="stickyWasDragged"
    :readonly="props.readonly"
    :class="{ 'no-flex-shrink': !shrink }"
    max-height="md"
    width="md"
    v-bind="props.dropdownProps"
    @open="handleOpen"
    @close="handleClose"
    @click.stop="handleDropdownClick"
    @pointerdown="handleDropdownPointerDown"
  >
    <template #trigger>
      <component
        :is="props.as"
        ref="triggerRef"
        v-bind="$attrs"
        data-ignore-click
        :aria-label="props.triggerAriaLabel ?? triggerAriaLabel"
        :class="[
          'attribute-chip',
          theme,
          {
            active: props.text || props.active,
            rounded: !props.text && !props.textPlaceholder,
            readonly: props.readonly,
          },
        ]"
        :tabindex="isCardActive ? 0 : -1"
      >
        <span v-if="$slots.icon" class="icon">
          <slot name="icon"></slot>
        </span>
        <span v-if="props.text || props.textPlaceholder" class="text">
          {{ props.text || props.textPlaceholder }}
        </span>
      </component>
    </template>

    <div
      v-focus-trap="{
        fallbackFocus: triggerRef,
        returnFocusOnDeactivate: cardMeta.wasActivatedByKeyboard,
      }"
      data-ignore-click
      class="dropdown-wrapper"
    >
      <slot />
    </div>
  </DropdownMenu>
</template>

<style scoped lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";
@use "@/styles/mixins/utils";

.dropdown-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
}

.no-flex-shrink {
  flex-shrink: 0;
}

.tooltip {
  display: inline-block;
  height: 100%;
}

.attribute-chip {
  display: inline-flex;
  align-items: center;
  padding: 3px 4px;
  height: 20px;
  border: 1px dashed currentcolor;
  user-select: none;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0;

  &:not(.readonly) {
    cursor: pointer;
  }

  &.light {
    color: colors-a11y.$outline-black;
  }

  &.dark {
    color: colors-a11y.$outline-white;
  }

  > * + * {
    margin-left: 4px;
  }

  &.rounded {
    border-radius: 50%;
    aspect-ratio: 1/1;
    justify-content: center;
  }

  .text {
    @include utils.ellipsis;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 14px;
    width: 14px;
  }

  &:hover:not(.active, :active, .readonly) {
    &.light {
      color: colors.$black-alpha-60;
    }

    &.dark {
      color: colors.$white-alpha-80;
    }
  }

  &.active {
    padding: 3px 4px;
    border: none;

    &.light {
      color: colors.$text;
      background-color: colors.$black-alpha-10;

      &:hover:not(.readonly) {
        background-color: colors.$black-alpha-20;
      }

      &:active:not(.readonly) {
        background-color: colors.$black-alpha-20;
      }
    }

    &.dark {
      color: colors.$text-inverse;
      background-color: colors.$white-alpha-10;

      &:hover:not(.readonly) {
        background-color: colors.$white-alpha-20;
      }

      &:active:not(.readonly) {
        background-color: colors.$white-alpha-20;
      }
    }
  }

  .wrapper {
    flex-grow: 1; /* Takes all available space */
    display: flex;
    flex-direction: column;
    min-height: 0; /* Overcomes the minimum height issue in flex children */
  }
}
</style>
