<template>
  <div class="row-container">
    <div class="info-container">
      <div class="first-row">
        <slot name="first-row"></slot>
      </div>
      <div class="second-row">
        <slot name="second-row"></slot>
      </div>
    </div>
    <slot name="button"></slot>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/mixins/utils";

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;

  .info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    width: 100%;

    .first-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 20px;
      gap: 8px;

      .objective-icon {
        color: colors-old.$text-secondary-color;
      }

      .text-wrapper {
        min-width: 0;

        .text {
          @include utils.ellipsis;

          max-width: 570px;
          font-weight: font.$weight-semi-bold;
          font-size: font.$size-normal;
        }
      }
    }

    .second-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 24px;

      > * {
        display: flex;
        align-items: center;
        font-weight: font.$weight-medium;
        font-size: font.$size-small;
        color: colors-old.$text-secondary-color;

        &:not(:first-child) {
          padding-left: 8px;
        }

        &:not(:last-child)::after {
          content: "";
          width: 1px;
          height: 8px;
          background-color: colors-old.$divider-color;
          border-radius: 1px;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
