<script setup lang="ts">
import { sortBy } from "lodash-es";
import { computed } from "vue";

import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import SelectUser from "@/components/ui/SelectUser/SelectUser.vue";
import { AuthUser } from "@/model/user";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useUserStore } from "@/store/user";

import AssigneeItemTooltip from "../tooltips/AssigneeItemTooltip.vue";
import { trackFilterOpen } from "../utils";

const store = useSearchMenuStore();
const currentUser = useUserStore().currentUser;

const selection = computed({
  get: () => store.selectedAssignees,
  set: (values: Array<{ id: string }>) => {
    store.selectedAssigneeIds = new Set(values.map((val) => val.id));
  },
});

/**
 * Returns all users currently assigned to any card on this board,
 * with the current user listed first
 */
const currentBoardAssignees = computed(() => {
  const users = store.allAssignees;
  const currentUserFirst = (user: AuthUser) => user.id !== currentUser?.id;

  return sortBy(users, [currentUserFirst]);
});
</script>

<template>
  <DropdownMenu
    class="search-select"
    width="sm"
    stay-open-on-content-click
    is-aria-menu
    @open="trackFilterOpen('assignee')"
  >
    <template #trigger="{ isOpen }">
      <IconButton
        icon="search/user"
        data-dropdown-trigger
        :activated="isOpen"
        :aria-label="
          $t('label.search.filterButton', { value: $t('general.assignees') })
        "
      >
        <template #tooltip>
          <AssigneeItemTooltip :assignees="selection" />
        </template>
      </IconButton>
      <InfoMarker v-if="selection.length" :top="4" :right="4" />
    </template>
    <SelectUser
      v-model="selection"
      :values="currentBoardAssignees"
      :header="$t('general.assignees')"
      scrollable
    />
  </DropdownMenu>
</template>
