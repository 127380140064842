<script setup lang="ts">
import { drawActions } from "@/action/drawActions";
import { toggleActions } from "@/action/toggleActions";
import ListItem from "@/components/ListItem/ListItem.vue";
import FluidBoard from "@/components/board/FluidBoard";
import { WindowCoordinate } from "@/model/coordinates";
import { useDrawStore } from "@/store/draw";

defineProps<{
  board: FluidBoard;
  position: WindowCoordinate;
}>();

defineEmits<{ dummy: [] }>();

function removeShape() {
  drawActions.removeShape("contextMenu", useDrawStore().selected!.shape.id);
}
</script>

<template>
  <div v-keyboard-nav style="width: 300px">
    <ListItem static class="title">
      {{ $t("contextMenu.general") }}
    </ListItem>
    <ListItem @click="toggleActions.showDraw('contextMenu')">
      {{ $t("contextMenu.drawModeOff") }}
      <span class="btn">{{ $t("key.escape") }}</span>
    </ListItem>

    <template v-if="useDrawStore().selected">
      <ListItem static class="title">{{ $t("shape.line") }}</ListItem>
      <ListItem @click="removeShape">
        {{ $t("general.delete") }}
        <span class="btn">{{
          drawActions.removeShape.data.shortcut?.name()
        }}</span>
      </ListItem>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/context-menu" as *;
</style>
