import { toggleActions } from "@/action/toggleActions";
import { sender } from "@/backend/Sender";
import { key } from "@/components/utils/Shortcuts";
import { windowToRelative } from "@/math/coordinate-systems";
import ShortcutUser from "@/mixins/ShortcutUser";
import { pointerPosInterval } from "@/model/Settings";
import {
  RelativeCoordinate,
  clientCoord,
  distance2,
  offScreen,
  windowCoord,
} from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { usePointerStore } from "@/store/pointer";

let trailTimeout = 0;
let pos = windowCoord(0, 0);
let lastPos = windowCoord(0, 0);

export function trail(start: boolean) {
  clearTimeout(trailTimeout);
  if (start) {
    sendPos();
  } else {
    doSendPos(offScreen);
  }
}

function sendPos() {
  if (distance2(pos, lastPos) > 25) {
    doSendPos(windowToRelative(pos));
    lastPos = pos;
  }
  trailTimeout = window.setTimeout(sendPos, pointerPosInterval);
}

function doSendPos(p: RelativeCoordinate) {
  void sender.pointerPos(useBoardStore().currentBoard().id, p);
  usePointerStore().set({ pos: p });
}

export function pointerTrail(component: ShortcutUser) {
  registerPointerEvents(component.$el as HTMLElement);
  registerShortcuts(component);
}

function registerShortcuts(comp: ShortcutUser) {
  comp.globalActionShortcut(toggleActions.showTrailingPointer);
  comp.globalShortcut(
    key("KeyA"),
    () => {
      usePointerStore().togglePointerActivation();
    },
    {
      up() {
        usePointerStore().activatePointer(false);
      },
    },
  );
}

function registerPointerEvents(el: HTMLElement) {
  el.addEventListener("pointermove", (e) => {
    pos = clientCoord(e);
  });

  el.addEventListener("pointerleave", (_) => {
    pos = windowCoord(-10, 10);
    lastPos = pos;
    doSendPos(offScreen);
  });
}
