<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import ObjectivesList from "@/components/objectives/ObjectivesList.vue";
import { BoardData } from "@/model/board";
import { useBoardsStore } from "@/store/boards";
import { teamObjectivesModalOpened } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import BaseModal from "./BaseModal.vue";

const props = defineProps<{ boardId: string }>();

const { t } = useI18n();

const board = computed(
  () => useBoardsStore().boardById(props.boardId) as BoardData<"team">,
);

onMounted(() => {
  trackEvent(teamObjectivesModalOpened());
});

const title = computed(() =>
  board.value.team
    ? t("teamObjectivesModal.objectives", {
        teamName: board.value.team.name,
      })
    : t("objectives.programObjectives"),
);
</script>

<template>
  <BaseModal
    class="team-objectives-modal"
    fullscreen
    aria-labelledby="team-objectives-modal-title"
    set-return-focus="#team-objectives-trigger"
    :description="$t('label.teamObjectivesModal.description')"
  >
    <template #header-title>
      <div class="header-title">
        <SvgIcon name="board/objectives" height="24" width="24" />
        <h2 id="team-objectives-modal-title" v-autofocus>{{ title }}</h2>
      </div>
    </template>

    <div class="main-container">
      <ObjectivesList :board="board" />
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
@use "@/styles/font";

.team-objectives-modal {
  .main-container {
    margin: 0 auto;
    width: 40vw;

    @media only screen and (width <= 1920px) {
      width: 700px;
    }

    @media only screen and (width <= 800px) {
      width: calc(100% - 15px);
    }
  }

  .header-title {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    margin: 0 auto;
    font-weight: font.$weight-bold;
    font-size: font.$size-large;

    svg.objective-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>

<style lang="scss">
@use "@/styles/variables";

.team-objectives-modal .body {
  padding: 0 !important;
}
</style>
