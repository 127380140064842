<script setup lang="ts">
import { computed } from "vue";

import { linkActions } from "@/action/linkActions";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/TypeDot.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import {
  isARTWorkItem,
  isDependency,
  isObjective,
  isOtherItem,
  isSticky,
  isTeamWorkItem,
} from "@/components/utils/cardLinks";
import { CardLink } from "@/model/link";
import { useWorkModeStore } from "@/store/workMode";

import FlagInfo from "./components/FlagInfo.vue";
import RowWrapper from "./components/RowWrapper.vue";
import StatusInfo from "./components/StatusInfo.vue";

const props = defineProps<{ link: CardLink }>();

const isExecutionMode = computed(() => useWorkModeStore().isExecutionMode);

const unlink = () => {
  if (isSticky(props.link)) {
    linkActions.remove("card", { linkId: props.link.id }, "link-remove-button");
  } else if (isObjective(props.link)) {
    linkActions.removeObjective(
      "card",
      props.link.cardId,
      props.link.boardId,
      props.link.objectiveId,
      "link-remove-button",
    );
  }
};
</script>

<template>
  <RowWrapper>
    <template #first-row>
      <SvgIcon
        v-if="isObjective(link)"
        name="objective/team-objectives"
        class="objective-icon"
        height="16"
        width="16"
      />
      <TypeDot v-else size="medium" :color="link.color" />
      <div class="text-wrapper">
        <BaseTooltip position="bottom">
          <div class="text">{{ link.text }}</div>
          <template #content>{{ link.text }}</template>
        </BaseTooltip>
      </div>
    </template>
    <template #second-row>
      <!-- ART work item -->
      <template v-if="isARTWorkItem(link)">
        <StatusInfo
          v-if="link.status"
          :status="link.status"
          data-testid="art-work-item-status"
        />
        <div>{{ link.type }}</div>
        <div v-if="link.almId">
          {{ link.almId }}
        </div>
        <div v-if="link.teamName">
          {{ link.teamName }}
        </div>
        <div v-if="link.iterationName">
          {{ link.iterationName }}
        </div>
        <FlagInfo v-if="link.flag" :flag="link.flag" />
      </template>

      <!-- Dependency -->
      <template v-if="isDependency(link)">
        <div>{{ link.type }}</div>
        <div>
          <span v-if="link.precondTeam">
            {{ link.precondTeam.name }}
          </span>
          <span v-else class="no-team">
            {{ $t("linkChip.noPrecondTeam") }}
          </span>
          <SvgIcon
            name="arrow/right-thin"
            class="arrow-icon"
            width="10"
            height="100%"
          />
          <span v-if="link.dependTeam">
            {{ link.dependTeam.name }}
          </span>
          <span v-else class="no-team">
            {{ $t("linkChip.noDependentTeam") }}
          </span>
        </div>
        <div v-if="link.iterationName">
          {{ link.iterationName }}
        </div>
        <FlagInfo v-if="link.flag" :flag="link.flag" />
      </template>

      <!-- Team work item -->
      <template v-if="isTeamWorkItem(link)">
        <StatusInfo
          v-if="link.status"
          :status="link.status"
          data-testid="team-work-item-status"
        />
        <div>{{ link.type }}</div>
        <div v-if="!isExecutionMode && link.points">
          {{ $t("linkChip.storyPoints", { points: link.points }) }}
        </div>
        <div v-if="link.almId">
          {{ link.almId }}
        </div>
        <div v-if="link.teamName">
          {{ link.teamName }}
        </div>
        <div v-if="link.iterationName">
          {{ link.iterationName }}
        </div>
        <FlagInfo v-if="link.flag" :flag="link.flag" />
      </template>

      <!-- Objective -->
      <template v-if="isObjective(link)">
        <div v-if="link.type === 'committed'">
          {{ $t("objectives.committed") }}
        </div>
        <div v-else>
          {{ $t("objectives.uncommitted") }}
        </div>
      </template>

      <!-- Other -->
      <template v-if="isOtherItem(link)">
        <div>{{ link.type }}</div>
        <div v-if="link.teamName">
          {{ link.teamName }}
        </div>
        <div v-if="link.iterationName">
          {{ link.iterationName }}
        </div>
        <FlagInfo v-if="link.flag" :flag="link.flag" />
      </template>
    </template>
    <template #button>
      <IconButton
        variant="ghost"
        icon="action-menu/unlink"
        data-testid="unlink-button"
        :aria-label="$t('linkableCardRow.removeLink', link.text)"
        :tooltip="$t('linkModal.removeLink')"
        @click="unlink"
        @keydown.enter="unlink"
      />
    </template>
  </RowWrapper>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;

.no-team {
  border: 1px dashed colors-old.$text-secondary-color;
  border-radius: 8px;
  padding: 0 4px;
}

.arrow-icon {
  margin-left: 8px;
  margin-right: 8px;
}
</style>
