<script lang="ts" setup>
import Fuse from "fuse.js";
import { computed, ref } from "vue";

import BaseListItem from "@/components/BaseList/components/BaseListItem/BaseListItem.vue";
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import type { Art } from "@/model/session";

const props = defineProps<{ arts: Art[]; selected: Art | null }>();
const emit = defineEmits<{ select: [art: Art | null] }>();

const filter = ref<string>("");
const arts = computed(() => {
  if (!filter.value) return props.arts;
  const fuse = new Fuse(props.arts, { keys: ["name"], isCaseSensitive: false });
  return fuse.search(filter.value).map((r) => r.item);
});

const handleArtChange = (art: Art | null) => {
  emit("select", art);
};
</script>

<template>
  <ComboBox
    v-model="filter"
    :title="$t('actions.art')"
    :placeholder="$t('action.searchArts')"
    :icon-title="$t('label.artSelector.searchTitle')"
    :list-len="arts?.length"
  >
    <BaseListItem
      v-if="selected"
      class="list-item"
      @click="handleArtChange(null)"
    >
      <template #before>
        <div style="width: 20px"></div>
      </template>
      {{ $t("general.noArt") }}
    </BaseListItem>

    <BaseListItem
      v-for="art in arts"
      :key="art.id"
      :active="art.id === selected?.id"
      class="list-item"
      @click="handleArtChange(art)"
    >
      <template #before>
        <SvgIcon name="action-menu/art" width="20" height="20" />
      </template>
      {{ art.name }}
    </BaseListItem>
  </ComboBox>
</template>
