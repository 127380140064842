<script setup lang="ts">
import { defineAsyncComponent, markRaw } from "vue";

import LoadingIndicator from "../LoadingIndicator.vue";

defineOptions({ inheritAttrs: false });

const components = {
  AGENDA_2_2: defineAsyncComponent(() => import("./BackgroundAgenda2x2.vue")),
  AGENDA_2_3: defineAsyncComponent(() => import("./BackgroundAgenda2x3.vue")),
  AGENDA_2_5: defineAsyncComponent(() => import("./BackgroundAgenda2x5.vue")),
  AGENDA_3_2: defineAsyncComponent(() => import("./BackgroundAgenda3x2.vue")),
  AGENDA_3_3: defineAsyncComponent(() => import("./BackgroundAgenda3x3.vue")),
  AGENDA_3_5: defineAsyncComponent(() => import("./BackgroundAgenda3x5.vue")),
  ART_TRACKS: defineAsyncComponent(() => import("./BackgroundArtTracks.vue")),
  BLANK: defineAsyncComponent(() => import("./BackgroundBlank.vue")),
  BUSINESS_MODEL: defineAsyncComponent(
    () => import("./BackgroundBusinessModel.vue"),
  ),
  CHECKLIST: defineAsyncComponent(() => import("./BackgroundChecklist.vue")),
  CONFIDENCE_VOTING: defineAsyncComponent(
    () => import("./BackgroundConfidenceVoting.vue"),
  ),
  CUSTOMER_JOURNEY: defineAsyncComponent(
    () => import("./BackgroundCustomerJourney.vue"),
  ),
  EARLY_TO_MATURE: defineAsyncComponent(
    () => import("./BackgroundEarlyToMature.vue"),
  ),
  EFFORT_MATRIX: defineAsyncComponent(
    () => import("./BackgroundEffortMatrix.vue"),
  ),
  EMPATHY_RETRO: defineAsyncComponent(
    () => import("./BackgroundEmpathyRetro.vue"),
  ),
  GOLDEN_CIRCLE: defineAsyncComponent(
    () => import("./BackgroundGoldenCircle.vue"),
  ),
  GRID_5_10: defineAsyncComponent(() => import("./BackgroundGrid5x10.vue")),
  IMPACT_EFFORT: defineAsyncComponent(
    () => import("./BackgroundImpactEffortMatrix.vue"),
  ),
  ISHIKAWA: defineAsyncComponent(() => import("./BackgroundIshikawa.vue")),
  KEY_TOPICS: defineAsyncComponent(() => import("./BackgroundKeyTopics.vue")),
  LEAN: defineAsyncComponent(() => import("./BackgroundLean.vue")),
  MAGIC: defineAsyncComponent(() => import("./BackgroundMagic.vue")),
  MATRIX: defineAsyncComponent(() => import("./BackgroundMatrix.vue")),
  PARKING: defineAsyncComponent(() => import("./BackgroundParking.vue")),
  PIPLANNING_PRODUCT: defineAsyncComponent(
    () => import("./BackgroundPiplanningProduct.vue"),
  ),
  PLUS_MINUS_DELTA: defineAsyncComponent(
    () => import("./BackgroundPlusMinusDelta.vue"),
  ),
  PORTFOLIO: defineAsyncComponent(() => import("./BackgroundPortfolio.vue")),
  PORTFOLIO_ROADMAP: defineAsyncComponent(
    () => import("./BackgroundPortfolioRoadmap2.vue"),
  ),
  PORTFOLIO_ROADMAP2: defineAsyncComponent(
    () => import("./BackgroundPortfolioRoadmap.vue"),
  ),
  PROBLEM_SOLVING: defineAsyncComponent(
    () => import("./BackgroundProblemSolving.vue"),
  ),
  RETRO: defineAsyncComponent(() => import("./BackgroundRetro.vue")),
  ROAM_KANBAN: defineAsyncComponent(() => import("./BackgroundRoamKanban.vue")),
  ROOT_CAUSE: defineAsyncComponent(() => import("./BackgroundRootCause.vue")),
  SAILBOAT: defineAsyncComponent(() => import("./BackgroundSailboat.vue")),
  SOS_8: defineAsyncComponent(() => import("./BackgroundSos8.vue")),
  SOS_8_2: defineAsyncComponent(() => import("./BackgroundSos82.vue")),
  SOS_SYNC: defineAsyncComponent(() => import("./BackgroundSosSync.vue")),
  STARFISH: defineAsyncComponent(() => import("./BackgroundStarfish.vue")),
  SWOT: defineAsyncComponent(() => import("./BackgroundSwot.vue")),
  SWOT_TOWS: defineAsyncComponent(() => import("./BackgroundSwotTows.vue")),
  SWOT_TOWS_EVAL: defineAsyncComponent(
    () => import("./BackgroundSwotTowsEval.vue"),
  ),
  TODO_PROGRESS_DONE: defineAsyncComponent(
    () => import("./BackgroundTodoProgressDone.vue"),
  ),
  USER_STORY_MAP: defineAsyncComponent(
    () => import("./BackgroundUserStoryMap.vue"),
  ),
  VALUE_STREAM: defineAsyncComponent(
    () => import("./BackgroundValueStream.vue"),
  ),
  VALUE_STREAM_IDENT: defineAsyncComponent(
    () => import("./BackgroundValueStreamIdent.vue"),
  ),
  WELCOME: defineAsyncComponent(() => import("./BackgroundWelcome.vue")),
  WORK_AGREEMENT: defineAsyncComponent(
    () => import("./BackgroundWorkAgreement.vue"),
  ),
  WORLD_MAP: defineAsyncComponent(() => import("./BackgroundWorldMap.vue")),
} as const;
type ComponentType = keyof typeof components;

defineProps<{ type: string }>();

const comps = markRaw(components);
</script>

<template>
  <suspense>
    <Component v-bind="$attrs" :is="comps[type as ComponentType]" />
    <template #fallback>
      <LoadingIndicator v-bind="$attrs" class="background-loader" global />
    </template>
  </suspense>
</template>

<style lang="scss" scoped>
.background-loader {
  width: 100%;

  // aspect-ratio should match background svgs' size,
  // so the loading placeholder occupies the same space
  aspect-ratio: 1920 / 1080;
}
</style>
