import { Vue } from "vue-class-component";

import EventBus from "@/EventBus";
import { Board } from "@/model/board";
import { Card } from "@/model/card";
import { ServerTime } from "@/model/timer";
import { SearchQuery } from "@/router/types";
import { EnlargeTrigger } from "@/utils/analytics/events";

// Card actions that can be passed through the event bus

export const sendSetting = (name: string, value: unknown) => sendKeyEvent("setting", name, value);
export const sendBoardSwitch = () => sendEvent("boardSwitch");
export const sendSearch = (search: SearchQuery) => sendEvent("search", search);
export const sendServerTick = (serverTime: ServerTime) => sendEvent("serverTick", serverTime);
export const sendBoardLoaded = (boardId?: Board["id"]) => sendEvent("boardLoaded", boardId);

/**
 * Trigger an action on the given card
 */
export type EventType =
  | "boardSwitch"
  | "setting"
  | "message"
  | "search"
  | "serverTick"
  | "stickyNoteAction"
  | "boardLoaded";

function sendEvent(name: EventType, data?: unknown): void {
  EventBus.emit(name, data);
}

function sendKeyEvent(name: EventType, key: string, data?: unknown) {
  EventBus.emit(name, { key, value: data });
}

function onEvent(name: EventType, callback: (e?: any) => void) {
  EventBus.on(name, callback);
}

function offEvent(name: EventType, callback: (e?: any) => void) {
  EventBus.off(name, callback);
}

export default class EventBusUser extends Vue {
  callbacks: Record<string, (e: unknown) => void> = {};

  onSetting(name: string, callback: (e: unknown) => void) {
    this._onKeyEvent("setting", name, callback);
  }

  onSearch(callback: (search: SearchQuery) => void) {
    this._onEvent("search", callback);
  }

  onBoardSwitch(callback: () => void) {
    this._onEvent("boardSwitch", callback);
  }

  onBoardLoaded(callback: (boardId: Board["id"]) => void) {
    this._onEvent("boardLoaded", (boardId: Board["id"]) => callback(boardId));
  }

  _onEvent(name: EventType, callback: (e?: any) => void) {
    this.callbacks[name] = callback;
    EventBus.on(name, callback);
  }

  _onKeyEvent(name: EventType, key: string, callback: (e: unknown) => void) {
    this._onEvent(name, (e) => {
      if (e.key === key) {
        callback(e.value);
      }
    });
  }

  unmounted() {
    for (const name in this.callbacks) {
      EventBus.off(name, this.callbacks[name]);
    }
  }
}

// ------------------------------ New sticky note actions ------------------------------

interface StickyNoteActionBase<T extends string> {
  action: T;
  id: Card["id"];
}

export type StickyNoteEnlarge = StickyNoteActionBase<"enlarge"> & { focus?: boolean; trigger?: EnlargeTrigger };
export type StickyNoteShrink = StickyNoteActionBase<"shrink">;
type StickyNoteChanged = StickyNoteActionBase<"changed">;

type StickyNoteAction = StickyNoteEnlarge | StickyNoteShrink | StickyNoteChanged;

type StickyNoteActionPayloads = {
  [K in StickyNoteAction["action"]]: Omit<Extract<StickyNoteAction, { action: K }>, "id">;
};

export const sendStickyNoteAction = <T extends StickyNoteAction["action"]>(
  id: string,
  action: StickyNoteActionPayloads[T],
) => {
  sendEvent("stickyNoteAction", { id, ...action });
};

export const onStickyNoteAction = <T extends StickyNoteAction["action"]>(
  id: Card["id"] | null,
  action: T,
  callback: (action: Extract<StickyNoteAction, { action: T }>) => void,
) => {
  onEvent("stickyNoteAction", (e: StickyNoteAction) => {
    if (id === null && e.action === action) {
      callback(e as Extract<StickyNoteAction, { action: T }>);
      return;
    }

    if (e.id === id && e.action === action) {
      callback(e as Extract<StickyNoteAction, { action: T }>);
    }
  });
};

export const offStickyNoteAction = <T extends StickyNoteAction["action"]>(
  callback: (action: Extract<StickyNoteAction, { action: T }>) => void, // Modify the callback function type here
) => {
  offEvent("stickyNoteAction", callback);
};
