<script setup lang="ts">
import { computed, ref } from "vue";

import { CardCreateProps } from "@/action/cardActions";
import FluidBoard from "@/components/board/FluidBoard";
import SelectNamedValue from "@/components/ui/SelectNamedValue/SelectNamedValue.vue";
import SelectStickyType from "@/components/ui/SelectStickyType/SelectStickyType.vue";
import { AlmSourceId } from "@/model/baseTypes";
import { AlmSource } from "@/model/board";
import { WindowCoordinate } from "@/model/coordinates";
import { StickyType } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";

const props = defineProps<{
  board: FluidBoard;
  position: WindowCoordinate;
  card: Partial<CardCreateProps>;
}>();

const emit = defineEmits<{
  select: [type: StickyType, almSourceId?: AlmSourceId];
}>();

const cardType = ref<StickyType | undefined>(props.card.type);
const almSourceId = ref<AlmSourceId | undefined>(props.card.almSourceId);
const emitSent = ref<boolean>(false);

const cardTypes = useBoardStore().creatableStickyTypes;
const almSources = computed(() =>
  cardType.value
    ? useBoardsStore().stickyTypeOriginBoard(cardType.value, props.position)
        .almSources
    : [],
);
const almSourceNeeded = computed(
  () => almSources.value.length > 1 && almSourceId.value === undefined,
);

function setType(type: StickyType) {
  cardType.value = type;
  if (!almSourceNeeded.value) {
    emitSelect();
  }
}

function typeSelected(e: Event) {
  if (almSourceNeeded.value) {
    e.stopPropagation();
  }
}

function setAlmSource(source: AlmSource) {
  almSourceId.value = source.id;
  emitSelect();
}

function emitSelect() {
  emit("select", cardType.value!, almSourceId.value);
  emitSent.value = true;
}
</script>

<template>
  <div
    v-scrollable-on-board
    style="width: 200px"
    :class="{ loading: emitSent }"
  >
    <SelectNamedValue
      v-if="cardType && almSourceNeeded"
      class="list"
      :header="$t('actions.selectProject')"
      :values="almSources"
      :disabled="emitSent"
      :model-value="almSources[0]"
      @update:model-value="setAlmSource($event as AlmSource)"
    />
    <SelectStickyType
      v-else
      class="list"
      :header="$t('actions.selectStickyType')"
      :sticky-types="cardTypes"
      :disabled="emitSent"
      :model-value="cardType || cardTypes[0]"
      @update:model-value="setType($event as StickyType)"
      @click="typeSelected"
    />
  </div>
</template>

<style lang="scss" scoped>
.loading {
  cursor: wait;
}

.list {
  :deep(.base-list-items) {
    max-height: 350px;
    overflow-y: auto;
  }
}
</style>
