<script setup lang="ts">
import { navigationActions } from "@/action/navigationActions";
import ActionButton from "@/components/ui/ActionButton/ActionButton.vue";
import { appLogoClicked } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

const handleClick = () => {
  trackEvent(appLogoClicked());
};
</script>

<template>
  <div class="exit-item">
    <ActionButton
      :action="navigationActions.goBack"
      icon="menu-top/logo"
      :aria-label="$t('label.menuTop.exitItem')"
      @click="handleClick"
    />
  </div>
</template>

<style lang="scss">
.exit-item {
  display: flex;

  svg {
    width: 25px !important;
    height: 25px !important;
    filter: grayscale(100%);
    transition: 0.3s ease-in-out filter;
  }

  .base-button {
    width: 40px;
    height: 40px;
  }

  &:hover {
    svg {
      filter: grayscale(0%);
    }
  }
}

.dark-mode {
  .exit-item:not(:hover) {
    svg path {
      fill: var(--text-secondary-color);
    }
  }
}
</style>
