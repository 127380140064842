import { Counter } from "./Counter";
import color from "./color.module.scss";
import { RelativeCoordinate, WindowCoordinate } from "./coordinates";
import { Icon } from "./icon";
import { AuthUser } from "./user";

export interface Id {
  id: string;
}

export type IdMap<T> = Record<string, T>;

export type WorkingMode = "planning" | "execution";

export type InfoLevel = "default" | "ok" | "warn" | "error";

export const infoLevelInfo: {
  [level in InfoLevel]: { color: string; backColor: string; icon: Icon };
} = {
  default: {
    color: color.back,
    backColor: "transparent",
    icon: "message/info",
  },
  ok: { color: color.ok, backColor: color.okBack, icon: "message/info" },
  warn: { color: color.warn, backColor: color.warnBack, icon: "message/warn" },
  error: {
    color: color.error,
    backColor: color.errorBack,
    icon: "message/error",
  },
};

// Our internal color type, representing [r, g, b, a], each on the range 0-1
export type Color = [number, number, number, number];

export type ArtId = number;

export const boardTypes = [
  "backlog",
  "program",
  "solution_backlog",
  "solution",
  "risk",
  "team",
  "flex",
  "objectives",
] as const;
export type BoardType = (typeof boardTypes)[number];

export function isBoardType(s: string) {
  return boardTypes.includes(s as BoardType);
}

export const riskTypes = ["R", "O", "A", "M", ""] as const;
export type RiskType = (typeof riskTypes)[number];

export const statusClasses = [
  "undefined",
  "todo",
  "in-progress",
  "done",
] as const;
export type StatusClass = (typeof statusClasses)[number];

export const statusClassNames: { [key in StatusClass]: string } = {
  undefined: /*$t*/ "statusClass.unknown",
  todo: /*$t*/ "statusClass.toDo",
  "in-progress": /*$t*/ "statusClass.inProgress",
  done: /*$t*/ "statusClass.done",
};

export type AlmSourceId = number | string;

export type ExecutionMode = "normal" | "preview" | "confirm";

type AlmType = "jira" | "ac" | "ado" | "";

export interface AlmTypeInfo {
  name: string;
  cockpitPath: string;
  icon: `alm/${Exclude<AlmType, "">}` | "general/minus";
  colorIcon: `alm/${Exclude<AlmType, "">}-color` | "general/minus";
  isUserMapped: boolean;
  hasAssignee: boolean;
}

export const almTypeInfo: { [type in AlmType]: AlmTypeInfo } = {
  jira: {
    name: "Jira",
    cockpitPath: "jira",
    icon: "alm/jira",
    colorIcon: "alm/jira-color",
    isUserMapped: true,
    hasAssignee: true,
  },
  ado: {
    name: "Azure DevOps",
    cockpitPath: "ado",
    icon: "alm/ado",
    colorIcon: "alm/ado-color",
    isUserMapped: false,
    hasAssignee: false,
  },
  ac: {
    name: "Rally",
    cockpitPath: "ac",
    icon: "alm/ac",
    colorIcon: "alm/ac-color",
    isUserMapped: true,
    hasAssignee: true,
  },
  "": {
    name: "",
    cockpitPath: "",
    icon: "general/minus",
    colorIcon: "general/minus",
    isUserMapped: false,
    hasAssignee: true,
  },
};

export interface Pointer {
  user: AuthUser;
  relativePos: RelativeCoordinate;
  windowPos: Array<{ pos: WindowCoordinate; index: number }>;
  timestamp: number;
}

export interface Statistics {
  calls: Counter;
  publishes: Counter;
  sends: Counter;
  receives: Counter;
  subscriptions: number;
  reconnects: number;
}
