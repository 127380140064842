<script setup lang="ts">
import { Ref, WatchStopHandle, computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useTippy } from "vue-tippy";

import { cardActions } from "@/action/cardActions";
import BasePopupFooter from "@/components/BasePopup/components/BasePopupFooter/BasePopupFooter.vue";
import BasePopupTitle from "@/components/BasePopup/components/BasePopupTitle/BasePopupTitle.vue";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import FlagPicker from "@/components/flags/FlagPicker.vue";
import CardFlag from "@/model/CardFlag";
import { isBacklogBoard } from "@/model/board";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import { useZoomStore } from "@/store/zoom";
import { stickyNoteAttributeChipClicked } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { isDarkColor } from "@/utils/color";
import { injectStrict } from "@/utils/vue";

import { colorMap, flagName, iconMap } from "./flagMap";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);
const triggerRef = ref<HTMLElement>();
const board = injectStrict(boardKey);
const dropdownRef = ref<typeof DropdownMenu>();
const tippyRef = ref<ReturnType<typeof useTippy>>();

const stickyNoteBackgroundColor = computed(() => {
  if (isBacklogBoard(board.value.type) && card.teamId) {
    return card.type.altColor;
  }
  return card.type.color;
});
const theme = computed(() => {
  return isDarkColor(stickyNoteBackgroundColor.value) ? "dark" : "light";
});

const handleFlagChange = (flag: CardFlag | null) => {
  cardActions.setFlag("card", card.id, flag || CardFlag.emptyFlag());
};
const { t } = useI18n();

onMounted(() => {
  tippyRef.value = useTippy(triggerRef, {
    content: t("general.stickers"),
    delay: [500, 0],
    animation: false,
    aria: { content: null },
  });
});

const iconName = computed(() => iconMap[card.flagType.icon]);
const colorName = computed(() => colorMap[card.flagType.colorIndex]);
const isActive = computed(() => !card.flagType.isEmpty());
const cardIsActive = computed(() => useBoardStore().activeCardId === card.id);
const iconAlt = computed(() => flagName(card.flagType));

const closeDropdown = () => dropdownRef.value?.close();

let zoomingWatcher: WatchStopHandle | undefined;
let boardChangeWatcher: WatchStopHandle | undefined;

const handleOpen = () => {
  const stickyType = card.type.functionality;
  const boardType = board.value.type;
  trackEvent(
    stickyNoteAttributeChipClicked(
      "flag-chip",
      stickyType,
      boardType,
      cardIsActive.value,
    ),
  );

  zoomingWatcher = watch(() => useZoomStore().zooming, closeDropdown);
  boardChangeWatcher = watch(() => useBoardStore().board?.id, closeDropdown, {
    once: true,
  });
};
const handleClose = () => {
  zoomingWatcher?.();
  boardChangeWatcher?.();
};
const dragWatcher: Ref<WatchStopHandle | null> = ref(null);
const stickyWasDragged = ref(false);

const handleDropdownPointerDown = () => {
  tippyRef.value?.disable();

  dragWatcher.value = watch(
    () => useDraggingStore().dragging[card.id],
    () => (stickyWasDragged.value = true),
  );
};

const handleDropdownClick = () => {
  dragWatcher.value?.();
  tippyRef.value?.enable();

  if (stickyWasDragged.value) {
    stickyWasDragged.value = false;
    return;
  }
};
</script>

<template>
  <div
    v-if="isActive || !cardMeta.isReadonly"
    class="flag-chip"
    :class="{ 'read-only': cardMeta.isReadonly }"
  >
    <DropdownMenu
      ref="dropdownRef"
      :disabled="stickyWasDragged"
      @click.stop="handleDropdownClick"
      @pointerdown="handleDropdownPointerDown"
      @open="handleOpen"
      @close="handleClose"
    >
      <template #trigger>
        <button
          ref="triggerRef"
          class="trigger"
          :class="[{ active: isActive, [colorName]: isActive }, theme]"
          :tabindex="cardIsActive ? 0 : -1"
        >
          <SvgIcon :name="iconName" :alt="iconAlt" :aria-label="iconAlt" />
        </button>
      </template>
      <div
        v-focus-trap="{
          fallbackFocus: triggerRef,
          returnFocusOnDeactivate: cardMeta.wasActivatedByKeyboard,
        }"
      >
        <BasePopupTitle v-autofocus>{{
          $t("general.stickers")
        }}</BasePopupTitle>
        <FlagPicker
          class="picker"
          data-ignore-click
          :selected="card.flagType"
          :no-sticker-option="false"
          @select="handleFlagChange"
          @deselect="handleFlagChange(null)"
          @scroll.stop.prevent
          @wheel.stop.prevent
        />
        <BasePopupFooter
          :disabled="!card.flagType"
          @click="handleFlagChange(null)"
          @keydown.enter.stop
          @keydown.space.stop
          >{{ $t("general.clear") }}
        </BasePopupFooter>
      </div>
    </DropdownMenu>
  </div>
</template>

<style scoped lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";

.flag-chip {
  max-height: 100%;

  &.read-only {
    pointer-events: none;
  }

  .tooltip {
    display: inline-block;
    height: 100%;
  }

  .trigger {
    display: grid;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 3px;
    border: 1px dashed currentcolor;
    border-radius: 50%;
    cursor: pointer;
    outline: revert;

    &.light {
      color: colors-a11y.$outline-black;
    }

    &.dark {
      color: colors-a11y.$outline-white;
    }

    &:hover:not(.active) {
      &.light {
        color: colors.$black-alpha-60;
      }

      &.dark {
        color: colors.$white-alpha-80;
      }
    }

    &.active {
      border: none;
      padding: 0;
    }

    $flag-colors: (
      blue: colors-old.$flag-color4,
      green: colors-old.$flag-color6,
      orange: colors-old.$flag-color2,
      purple: colors-old.$flag-color5,
      red: colors-old.$flag-color1,
      yellow: colors-old.$flag-color3,
    );

    @each $color-name, $color-value in $flag-colors {
      &.#{"#{$color-name}"} {
        color: $color-value;
      }
    }
  }
}

.picker {
  font-size: 10px;
  padding: 5px;
}
</style>
