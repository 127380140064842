<script setup lang="ts">
import { computed } from "vue";

import { drawActions } from "@/action/drawActions";
import { toggleActions } from "@/action/toggleActions";
import drawLines from "@/assets/draw-lines.gif";
import ActionButton from "@/components/ui/ActionButton/ActionButton.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { useDrawStore } from "@/store/draw";
import { useToastStore } from "@/store/toast";

import MenuItemDelimiter from "../../MenuItemDelimiter.vue";

const tool = computed(() => useDrawStore().tool);

function showLineToast() {
  useToastStore().show(/*$t*/ "message.lineTool", {
    titleKey: /*$t*/ "action.lineTool",
    imageUrl: drawLines,
    closeable: true,
  });
}
</script>

<template>
  <ActionButton :action="drawActions.moveTool" :activated="tool === 'move'" />
  <ActionButton
    :action="drawActions.lineTool"
    :activated="tool === 'line'"
    @click="showLineToast"
  />
  <MenuItemDelimiter />
  <BaseButton variant="ghost" @click="toggleActions.showDraw('mainMenu')">
    {{ $t("contextMenu.drawModeDone") }}
  </BaseButton>
</template>
