import { ErrorCode, JSONCodec, NatsConnection, headers } from "nats.ws";

import { captureException, captureMessage } from "@/error/sentry";
import { goToLogout } from "@/router/navigation";
import { useClientStore } from "@/store/client";
import { useStatisticsStore } from "@/store/statistics";

import { BackendError } from "./BackendError";
import {
  BackendSession,
  SubscriptionHandler,
  SubscriptionLevel,
  createDummySubscription,
} from "./BackendSession";
import { BaseBackendSession } from "./BaseBackendSession";
import { EventInfo } from "./EventInfo";
import { RpcBackend } from "./RpcBackend";

export class NatsBackendSession
  extends BaseBackendSession
  implements BackendSession
{
  private readonly codec = JSONCodec();
  private readonly userId;
  private loggingOut = false;

  constructor(
    private connection: NatsConnection,
    user: { company: string; id: string },
    rpcBackend: RpcBackend,
  ) {
    super(user.company, rpcBackend);
    this.userId = user.id;
  }

  doSubscribe<TArgs>(
    path: string,
    handler: SubscriptionHandler<TArgs>,
    level: SubscriptionLevel,
  ) {
    try {
      const sub = this.connection.subscribe(path, {
        callback: (error, msg) => {
          if (error) {
            if (!this.loggingOut) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
              this.loggingOut ||= error.code === ErrorCode.PermissionsViolation;
              void captureException(error, {
                subscriptions: {
                  origin: "subscription",
                  topic: path,
                },
              }).finally(() => {
                if (this.loggingOut) {
                  void goToLogout();
                }
              });
            }
            return;
          }
          if (msg.headers?.get?.("clientId") === useClientStore().clientId) {
            // Ignore the messages we sent ourselves
            return;
          }
          useStatisticsStore().current.receives.addOk();
          // TODO casting to TArgs is not safe
          const body = this.codec.decode(msg.data) as {
            args: TArgs;
            string: unknown;
          };
          const { args, ...kwargs } = body;
          const details = new EventInfo(msg.subject);
          handler(args, kwargs, details);
        },
      });
      this.subscriptions[level].push(sub);
      return sub;
    } catch (error) {
      throw new BackendError("Subscribe Error", `Error subscribing ${path}`, {
        request: { path },
        response: error as Record<string, unknown>,
      });
    }
  }

  doUnsubscribe(level: SubscriptionLevel, connectionOpen: boolean) {
    if (this.connection.isClosed()) {
      return super.doUnsubscribe(level, false);
    }
    return super.doUnsubscribe(level, connectionOpen);
  }

  almSubscribe<TArgs>(path: string, handler: SubscriptionHandler<TArgs>) {
    if (this.almSyncId) {
      return this.doSubscribe(
        this.almTopic(path, { subscribe: true }),
        handler,
        "session",
      );
    }
    return createDummySubscription(path);
  }

  roomSubscribe<TArgs>(path: string, handler: SubscriptionHandler<TArgs>) {
    return this.doSubscribe(
      this.roomTopic(path, { subscribe: true }),
      handler,
      "room",
    );
  }

  sessionSubscribe<TArgs>(path: string, handler: SubscriptionHandler<TArgs>) {
    return this.doSubscribe(
      this.sessionTopic(path, { subscribe: true }),
      handler,
      "session",
    );
  }

  boardSubscribe<TArgs>(
    boardId: string,
    path: string,
    level: SubscriptionLevel,
    handler: SubscriptionHandler<TArgs>,
  ) {
    const topic = this.sessionBoardTopic(boardId, path, { subscribe: true });
    return this.doSubscribe(topic, handler, level);
  }

  boardPublish(boardId: string, path: string, value: unknown[]) {
    if (!boardId) {
      void captureMessage("Publish with empty boardId", {
        info: { path, boardId },
      });
      return;
    }
    if (this.connection.isClosed()) {
      return;
    }
    try {
      const callHeaders = headers();
      callHeaders.append("correlation-id", useClientStore().newCorrelationId());
      callHeaders.append("clientId", useClientStore().clientId);

      const topic = this.sessionBoardTopic(boardId, path, {
        subscribe: false,
      });
      this.connection.publish(topic, this.codec.encode({ args: value }), {
        headers: callHeaders,
      });
      useStatisticsStore().current.publishes.addOk();
    } catch (error) {
      useStatisticsStore().current.publishes.addFail(error);
      throw error;
    }
  }

  private basePath(module: string, subscribe = true) {
    return `piplanning.${module}.6.${this.company}.${subscribe ? "*" : this.userId}`;
  }

  private sessionBoardTopic(
    boardId: string,
    path: string,
    { subscribe = true } = {},
  ) {
    boardId = boardId === "" ? "*" : boardId;
    const base = this.basePath("board", subscribe);

    return `${base}.${this.sessionId}.${boardId}.${path}`;
  }

  private roomTopic(path: string, { subscribe = true } = {}) {
    return `${this.basePath("session", subscribe)}.${path}`;
  }

  private sessionTopic(path: string, { subscribe = true } = {}) {
    return `${this.basePath("session", subscribe)}.${this.sessionId}.${path}`;
  }

  private almTopic(path: string, { subscribe = true } = {}) {
    return `${this.basePath("almsession", subscribe)}.${this.almSyncId}.${path}`;
  }
}
