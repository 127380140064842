<template>
  <base-planning-board
    ref="board"
    :planning-board="board"
    :group-iterations="groupIterations"
    :cards-for-distribution="cardsForDistribution"
    :field-class="fieldClass"
    :aria-label="$t('board.solution')"
    tabindex="0"
    @click="boardComponent()?.blur()"
  />
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Provide } from "vue-property-decorator";

import { BoardIteration } from "@/model/board";
import { Group } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardsStore } from "@/store/boards";
import { useSessionStore } from "@/store/session";

import BasePlanningBoard from "./BasePlanningBoard.vue";
import { boardKey } from "./injectKeys";

@Component({ components: { BasePlanningBoard } })
export default class SolutionBoard extends Vue {
  @Provide({ to: boardKey as symbol })
  get board() {
    return useBoardsStore().boardByType("solution");
  }

  get groupIterations() {
    // TODO this is used to show load/capacity on the overview modal (and for coloring overloaded fields)
    // replace useIterationStore().iterations with the real calculated values for load/capacity
    // see https://rentouch.atlassian.net/browse/REN-11477
    return Object.fromEntries(
      useArtStore().arts.map((art) => [art.id, useSessionStore().iterations]),
    );
  }

  fieldClass(group: Group, _iter?: BoardIteration) {
    return group.name === useArtStore().currentArt.name ? "group-current" : "";
  }

  get cardsForDistribution() {
    // FIXME: https://rentouch.atlassian.net/browse/REN-11477
    return [];
  }

  boardComponent() {
    return this.$refs.board as BasePlanningBoard;
  }

  // This must be callable by parent component
  focus() {
    this.boardComponent()?.focus();
  }
}
</script>
