<script lang="ts" setup>
import { computed } from "vue";

import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/vue";

import * as actions from "../../actions";
import { isActionVisible } from "../../utils";
import ActionMenuItem from "../ActionMenuItem/ActionMenuItem.vue";
import DeleteSticky from "./DeleteSticky.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const isDeleteVisible = computed(() => {
  return (
    isActionVisible("delete", card, board.value) &&
    !actions.cardWasCreatedOnDifferentBoard(card, board.value)
  );
});
const isDisabled = computed(() => {
  return !actions.isStickyDeletable(card, board.value);
});
</script>

<template>
  <ActionMenuItem
    v-if="isDeleteVisible"
    width="auto"
    name="delete"
    :disabled="isDisabled"
    :tooltip-text="
      isDisabled
        ? $t('stickyNote.deleteStickyNoteDisabled')
        : $t('cardAction.delete')
    "
    :aria-label="$t('label.actionMenu.delete')"
  >
    <SvgIcon name="action-menu/delete" width="20" height="20" />
    <template #dropdown><DeleteSticky mode="delete" /></template>
  </ActionMenuItem>

  <ActionMenuItem
    v-else
    width="auto"
    name="remove"
    :tooltip-text="$t('cardAction.remove')"
    :aria-label="$t('label.actionMenu.remove')"
  >
    <SvgIcon name="action-menu/remove" width="20" height="20" />
    <template #dropdown><DeleteSticky mode="remove" /></template>
  </ActionMenuItem>
</template>
