import { captureMessage } from "@/error/sentry";
import { boardTypeName } from "@/model/baseTypeI18n";
import { BoardType, isBoardType } from "@/model/baseTypes";
import { boardUrl } from "@/router/navigation";
import { AppParams } from "@/router/types";
import { i18n } from "@/translations/i18n";

import { Lines } from "./lineBreaker";
import { convertCharToHtmlEntity } from "./text";

interface TextAndLinks {
  text: string;
  links: PositionedLink[];
}

interface PositionedLink extends Link {
  pos: number;
}

// router.resolve should do this, but does not work as expected
function resolveAppUrl(url: string): AppParams | undefined {
  const [hash, app, session, team, rawBoard, rawName] = url
    .substring(url.indexOf("#"))
    .split("/");
  if (hash === "#" && app === "app" && rawBoard) {
    const [board, name] = rawName
      ? [rawBoard, withoutQuery(rawName)]
      : [withoutQuery(rawBoard), ""];
    return { session, team, board, name };
  }
}

function withoutQuery(s: string) {
  const pos = s.indexOf("?");
  return pos < 0 ? s : s.substring(0, pos);
}

export function replaceLinks(s: string): TextAndLinks {
  const links = new Array<PositionedLink>();
  let delta = 0;
  return {
    links,
    text: s.replace(
      /(https?:\/\/)(\S*)|#(\S*)/g,
      (all, protocol, path, hash, pos) => {
        if (protocol) {
          const urlParts = parseUrl(path);
          const link = urlParts
            ? urlPartsToLink(urlParts)
            : { href: all, newTab: true, text: path };
          return addLink(all, pos, link);
        }
        const hashParts = parseHash(hash);
        return hashParts ? addLink(all, pos, urlPartsToLink(hashParts)) : all;
      },
    ),
  };

  function addLink(source: string, pos: number, link: Link) {
    links.push({ pos: pos + delta, ...link });
    delta += link.text.length - source.length;
    return link.text;
  }
}

export function toHtmlWithLinks(
  s: string,
  links: PositionedLink[],
  lines: Lines,
): string[] {
  const res = new Array<string>();
  let text = "";
  let delta = 0;
  let linkIndex = 0;
  let link = links[linkIndex];
  let linkStart = 0;
  let lineEnd = 0;
  for (const [startPos, endPos] of lines) {
    const skipped = startPos - lineEnd;
    delta -= skipped;
    if (linkStart) {
      linkStart += skipped;
    }
    const lineStart = startPos + delta;
    for (let pos = startPos; pos < endPos; ) {
      if (pos === link?.pos || linkStart) {
        const target = link.newTab ? ' target="_blank"' : "";
        const linkEnd = Math.min(link.text.length, linkStart + endPos - pos);
        const linkText = link.text.substring(linkStart, linkEnd);
        pos += append(
          `<a href="${link.href}"${target} tabindex="-1">${linkText}</a>`,
          linkEnd - linkStart,
        );
        if (linkEnd === link.text.length) {
          linkStart = 0;
          link = links[++linkIndex];
        } else {
          linkStart = linkEnd;
        }
      } else {
        pos += append(convertCharToHtmlEntity(s, pos), 1);
      }
    }
    lineEnd = endPos;
    res.push(text.substring(lineStart, endPos + delta));
  }
  return res;

  function append(a: string, consume: number) {
    text += a;
    delta += a.length - consume;
    return consume;
  }
}

export function normalizeUrlParam(param: string | string[]) {
  // this is ok as route params can only be arrays when it's a repeatable param
  const s = param as string;
  try {
    return s ? decodeURIComponent(s) : s;
  } catch (e) {
    // when the url contains a % that is not part of an encoded char
    void captureMessage(`Invalid url parameter '${s}'`);
    return s;
  }
}

interface UrlParts {
  session: string | undefined;
  board: BoardType;
  spec: string | undefined;
}

interface Link {
  href: string;
  newTab: boolean;
  text: string;
}

function urlPartsToLink(url: UrlParts): Link {
  return {
    href: boardUrl(url.session, url.board, url.spec).href,
    newTab: false,
    text:
      //TODO use boardTitle()
      i18n.global.t(boardTypeName(url.board)) +
      (url.spec ? " " + url.spec : ""),
  };
}

function parseUrl(url: string): UrlParts | null {
  if (url.startsWith(location.host)) {
    const params = resolveAppUrl(url);
    if (params && isBoardType(params.board)) {
      return {
        session: normalizeUrlParam(params.session),
        board: params.board as BoardType,
        spec:
          params.board === "flex"
            ? normalizeUrlParam(params.name)
            : params.board === "team"
              ? normalizeUrlParam(params.team)
              : undefined,
      };
    }
  }
  return null;
}

function parseHash(hash: string): UrlParts | null {
  const parts = hash.split("/");
  if (parts.length >= 3) {
    const second = boardType(parts[1]);
    return second ? { session: parts[0], board: second, spec: parts[2] } : null;
  }
  const first = boardType(parts[0]);
  if (first) {
    return { session: undefined, board: first, spec: parts[1] };
  }
  const secondOnly = boardType(parts[1]);
  return secondOnly
    ? { session: parts[0], board: secondOnly, spec: undefined }
    : null;
}

function boardType(s: string): BoardType | null {
  const r = s === "canvas" ? "flex" : s;
  return isBoardType(r) ? (r as BoardType) : null;
}
