<template>
  <svg
    class="link-layer"
    :viewBox="`0 0 ${width} ${height}`"
    preserveAspectRatio="none"
    :style="{ stroke: color, zIndex }"
    style="fill: transparent"
    tabindex="-1"
    aria-hidden="true"
  >
    <defs>
      <g id="flashIcon" class="no-css-reset">
        <circle cx="10" cy="10" r="10" />
        <path d="M9 16v-5H5l6-7v5h4l-6 7z" fill="white" />
      </g>
    </defs>
    <g v-for="(link, linkIndex) in links" :key="linkIndex">
      <defs v-if="mask(link)">
        <mask :id="link.id" class="no-css-reset">
          <rect
            x="0"
            y="0"
            width="1600"
            height="900"
            fill="white"
            stroke="none"
          />
          <rect
            v-for="(clip, clipIndex) in link.clips"
            :key="clipIndex"
            v-bind="clip"
            fill="black"
            stroke="none"
          />
        </mask>
      </defs>
      <path :style="link.style" :d="link.curve" :mask="mask(link)" />
      <path
        v-if="link.arrow"
        :fill="link.style.stroke"
        stroke="none"
        :d="link.arrow"
        :mask="mask(link)"
      />
      <use
        v-if="showRiskyIcons && link.icon"
        href="#flashIcon"
        :fill="link.style.stroke"
        stroke="transparent"
        :x="link.icon.x"
        :y="link.icon.y"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { CSSProperties } from "vue";
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import QuadraticSpline from "@/math/QuadraticSpline";
import EventBusUser from "@/mixins/EventBusUser";
import { arrowAngle, arrowBoxFactor, arrowLen } from "@/model/Settings";
import { Board } from "@/model/board";
import { BoardCard } from "@/model/card";
import { linkColors } from "@/model/colors";
import {
  RelativeCoordinate,
  minus,
  plus,
  relativeCoord,
  times,
} from "@/model/coordinates";
import { isFaded, minFaded } from "@/model/markMode";
import variables from "@/model/variable.module.scss";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import { LinkedCards, useLinkStore } from "@/store/link";
import { useZoomStore } from "@/store/zoom";

import { showIcon, showLink } from "./linkLayer";

interface Path {
  id: string;
  curve: string;
  style: CSSProperties;
  arrow?: string;
  icon?: RelativeCoordinate;
  clips: Array<{
    x: number;
    y: number;
    width: number;
    height: number;
  }>;
}

@Component({})
export default class LinkLayer extends mixins(EventBusUser) {
  @Prop(Object) readonly board!: Board;
  @Prop(String) readonly color!: string;
  // links with the master card (either the pinned card or the active card) are on the priority z-index
  @Prop(Boolean) readonly priority!: boolean;
  width = 1600;
  height = 900;

  get zIndex() {
    return this.priority
      ? (this.masterCard()?.meta.zIndex || this.board.maxZ) - 1
      : variables.zIndexLinks;
  }

  get showRiskyIcons() {
    return useBoardStore().showRiskyLinks;
  }

  mask(path: Path) {
    return path.clips.length > 0 ? `url(#${path.id})` : "";
  }

  get links() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const res = new Array<Path>();

    useLinkStore().linksOnBoard.forEach((linked) => this.addLink(res, linked));
    if (this.priority) {
      useDraggingStore().draggedLinks.forEach(({ card, drag }) =>
        this.addDragLink(res, card, drag.id),
      );
    }
    return res;
  }

  addDragLink(links: Path[], from: BoardCard, dragId: string) {
    const c = this.coordinateById(dragId);
    if (c) {
      const spline = QuadraticSpline.forLink(from.meta.pos, c);
      links.push({
        id: dragId,
        style: { stroke: this.color },
        curve: spline.asPath(this.width, this.height),
        clips: [],
      });
    }
  }

  addLink(links: Path[], linked: LinkedCards) {
    // when zooming with zoom layer, don't show priority links
    // because clipping links by stickies does not work on zoom layer
    const zooming = useZoomStore().zoomingWithLayer;
    if (
      !showLink(linked) ||
      (zooming && this.priority) ||
      (!zooming &&
        this.isLinkedCard(linked, this.masterCard()) !== this.priority)
    ) {
      return;
    }

    const icon = showIcon(linked.link);
    const color = icon ? linkColors[linked.link.state] : this.color;
    const markMode = icon
      ? "normal"
      : minFaded(linked.from.meta.mark, linked.to.meta.mark);
    const transparency = markMode === "normal" ? "ff" : "80";

    const p0 = this.coordinateOf(linked.from);
    const p1 = this.coordinateOf(linked.to);
    const spline = QuadraticSpline.forLink(p0, p1);
    const path: Path = {
      id: linked.link.id,
      style: { stroke: color + transparency },
      curve: spline.asPath(this.width, this.height),
      clips: [],
    };

    if (icon) {
      path.icon = spline.iconPosition(this.width, this.height, 16 / 9);
    }

    const cardSize = times(
      relativeCoord(this.height, this.width),
      useBoardStore().currentBoard().cardSize.factor / 100000,
    );

    // clip the link if needed so that it does not overlap a card with lower z-index
    if (this.priority) {
      // clip the other side of the master card link
      path.clips.push(
        this.clip(
          this.masterCard()?.data.id === linked.from.data.id ? p1 : p0,
          cardSize,
        ),
      );
    } else {
      // no master card -> clip links to faded cards
      if (isFaded(linked.from.meta.mark)) {
        path.clips.push(this.clip(p0, cardSize));
      }
      if (isFaded(linked.to.meta.mark)) {
        path.clips.push(this.clip(p1, cardSize));
      }
    }

    if (linked.from.data.type.id === linked.to.data.type.id) {
      path.arrow = this.arrow(spline, p1, times(cardSize, arrowBoxFactor));
    }

    links.push(path);
  }

  clip(c: RelativeCoordinate, cardSize: RelativeCoordinate) {
    return {
      x: (c.x - cardSize.x) * this.width,
      y: (c.y - cardSize.y) * this.height,
      width: 2 * cardSize.x * this.width,
      height: 2 * cardSize.y * this.height,
    };
  }

  arrow(
    spline: QuadraticSpline,
    p: RelativeCoordinate,
    cardSize: RelativeCoordinate,
  ) {
    const tMax = spline.tMaxAtRectangle(minus(p, cardSize), plus(p, cardSize));
    const point = spline.interpolate(tMax);
    const angle = spline.angleAtT(tMax);
    return (
      this.command("M", point, 0, 0) +
      this.command(
        "L",
        point,
        arrowLen * Math.cos(angle - arrowAngle),
        arrowLen * Math.sin(angle - arrowAngle),
      ) +
      this.command(
        "L",
        point,
        arrowLen * Math.cos(angle + arrowAngle),
        arrowLen * Math.sin(angle + arrowAngle),
      ) +
      " l 0 0 z"
    );
  }

  command(cmd: string, base: RelativeCoordinate, x: number, y: number) {
    return ` ${cmd} ${(base.x + x) * this.width} ${(base.y + y) * this.height}`;
  }

  isLinkedCard(linked: LinkedCards, card: BoardCard | null): boolean {
    const id = card?.data.id;
    return id === linked.from.data.id || id === linked.to.data.id;
  }

  // either the pinned or the active card id
  masterCard() {
    return useLinkStore().markingCardLinkedCards ?? useBoardStore().activeCard;
  }

  coordinateOf(card: BoardCard): RelativeCoordinate {
    return this.coordinateById(card.data.id) || card.meta.pos;
  }

  coordinateById(id: string): RelativeCoordinate | undefined {
    return useDraggingStore().findById(id)?.pos;
  }
}
</script>

<style lang="scss">
@use "@/styles/z-index";

.link-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
