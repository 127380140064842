import { RouteLocation, RouteLocationNamedRaw, Router } from "vue-router";

import { BoardType } from "@/model/baseTypes";
import { Team } from "@/model/session";

export const currentTeam = "_";

export type PiVueRouter = Router & {
  navigatingTo?: RouteLocation;
  navigating: Promise<void>;
  syncPush: (f: () => RouteLocationNamedRaw) => ReturnType<Router["push"]>;
  syncReplace: (
    f: () => RouteLocationNamedRaw,
  ) => ReturnType<Router["replace"]>;
};

export type AppParams = {
  [param in "session" | "team" | "board" | "name"]: string;
};

export type LogoutReason =
  | "jwt-revoked"
  | "refresh-failed"
  | "connection-refused"
  | "user-invoked"
  | "unknown";

export type BoardTarget =
  | BoardType
  | { type: "flex"; name?: string }
  | { type: Exclude<BoardType, "flex">; team?: Team; artId?: string };

export interface QueryImpl {
  zoom?: string;
  scrollX?: string;
  scrollY?: string;
  searchText?: string;
  searchFlag?: string;
  searchAssignee?: string;
  searchTeam?: string;
  searchArt?: string;
  searchIteration?: string;
  searchType?: string;
  searchLink?: string;
  searchStatusClass?: string;
  searchPos?: string;
  searchId?: string;
  searchDepLink?: string;
  feature?: string | string[];
  category?: string;
}

export interface Params {
  session?: string;
  team?: string;
  art?: string;
  board?: string;
  name?: string;
}

export interface Query {
  zoom?: string;
  scrollX?: string;
  scrollY?: string;
  search?: SearchQuery;
  category?: string;
}

export interface SearchQuery {
  text?: string;
  flag?: string;
  assignee?: string;
  team?: string;
  art?: string;
  iteration?: string;
  type?: string;
  depLink?: string;
  link?: string;
  statusClass?: string;
  pos?: string;
  id?: string;
}
