import { defineStore } from "pinia";

import { InfoLevel } from "@/model/baseTypes";
import { Icon } from "@/model/icon";

export interface Toast {
  messageKey: string;
  titleKey?: string;
  icon?: Icon;
  imageUrl?: string;
  duration: number;
  closeable: boolean;
  invisible?: boolean; // for screen-reader-only notifications
  level?: InfoLevel;
}

export const useToastStore = defineStore("toast", {
  state: () => {
    return {
      toast: { messageKey: "", duration: 0, closeable: false } as Toast,
    };
  },
  actions: {
    show(messageKey: string, options?: Partial<Toast>) {
      if (!localStorage.getItem(hideKey(messageKey))) {
        this.toast = {
          messageKey,
          duration: 5000,
          closeable: false,
          ...options,
        };
        if (this.toast.duration > 0) {
          setTimeout(this.hide, this.toast.duration);
        }
      }
    },
    hide(noShowAgain?: boolean) {
      if (noShowAgain && this.toast.messageKey) {
        localStorage.setItem(hideKey(this.toast.messageKey), "true");
      }
      this.toast.messageKey = "";
    },
  },
});

function hideKey(key: string) {
  return `hide-${key}`;
}
