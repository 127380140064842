<script setup lang="ts">
import { clamp } from "lodash-es";
import { ref } from "vue";

import { cardActions } from "@/action/cardActions";
import BasePopupTitle from "@/components/BasePopup/components/BasePopupTitle/BasePopupTitle.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import BoardNumberInput from "@/components/input/BoardNumberInput.vue";
import { stickyNoteStoryPointsChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const dropdown = injectStrict(dropdownKey);

const props = defineProps<{
  trigger: "action-menu" | "attribute-chip"; // used for event tracking analytics
}>();

const points = ref(card.points);
const pointValues = [0, 0.5, 1, 2, 3, 5, 8, 13, 20, 40, 100];

const selectPoints = (points: number): void => {
  trackEvent(
    stickyNoteStoryPointsChanged(
      board.value.type,
      card.type.functionality,
      props.trigger,
    ),
  );

  cardActions.setPoints("card", card.id, points);
  dropdown.close();
};

const setInput = (val: number) => {
  if (!isFinite(val)) return 0;
  points.value = clamp(+val.toFixed(1), 0, 999.9);
};
</script>

<template>
  <div
    class="story-points-container"
    role="dialog"
    aria-labelledby="set-story-points-title"
    :aria-description="$t('label.stickyNote.storyPointsDialogDescription')"
  >
    <BasePopupTitle id="set-story-points-title">
      {{ $t("statusDistribution.storyPoints") }}
    </BasePopupTitle>
    <span class="story-points-input-wrap">
      <BoardNumberInput
        v-autofocus
        class="story-points-input"
        :value="points"
        :maxlength="5"
        allow-float
        data-testid="story-points-input"
        aria-labelledby="set-story-points-title"
        @input="setInput"
        @keydown.enter="selectPoints(points)"
      />
    </span>
    <div class="points">
      <button
        v-for="(point, index) in pointValues"
        :key="index"
        class="item"
        :class="{ active: point === points }"
        :aria-current="point === points"
        @click.stop="selectPoints(point)"
      >
        {{ point }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";

.story-points-container {
  width: 200px;
}

.story-points-input-wrap {
  display: flex;
  height: 40px;
  margin: 6px 6px 2px;

  .story-points-input {
    text-align: center;
    font-size: font.$size-normal;
    font-weight: font.$weight-medium;
    color: colors-a11y.$placeholder;
  }

  .story-points-input:focus {
    color: colors-old.$text-primary-color;
  }
}

.points {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 6px;
  align-items: center;

  .item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: colors-old.$light-background-color;
    font-size: font.$size-normal;
    font-weight: font.$weight-medium;
    color: colors-old.$text-primary-color;
    border-radius: 4px;
    width: 60px;
    height: 40px;
    cursor: pointer;
    outline: revert;
  }

  .item:hover {
    background-color: colors-old.$divider-color;
  }

  .active {
    background-color: rgba(colors-old.$primary-color, 0.08);
  }
}
</style>
