<script setup lang="ts">
import { computed, onMounted, watch } from "vue";

import { useEventBus } from "@/composables/useEventBus";
import { dragStartDist, maxZoom, minZoom } from "@/model/Settings";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useModalStore } from "@/store/modal";

import LoadingIndicator from "./LoadingIndicator.vue";
import NetStatistics from "./NetStatistics/NetStatistics.vue";
import GlobalError from "./modal/GlobalError.vue";
import * as Gestures from "./utils/Gestures";
import { resetZoom } from "./utils/boardSize";

const modal = computed(() => useModalStore().modal);
const { onBoardSwitch } = useEventBus();

onMounted(() => {
  onBoardSwitch(resetZoom);
  Gestures.init(dragStartDist, minZoom, maxZoom);
  watch(
    () => useClientSettingsStore().stickyFont,
    (font, old) => stickyFontCss(font, !!old),
    { immediate: true },
  );
});

function stickyFontCss(fontFamily: string, replace: boolean) {
  const css = document.styleSheets[0];
  if (replace) {
    css.deleteRule(0);
  }
  css.insertRule(`:root { --card-font: ${fontFamily} }`, 0);
}
</script>

<template>
  <div>
    <RouterView />
    <NetStatistics />
    <GlobalError />
    <LoadingIndicator global background />
    <!-- @vue-ignore -->
    <Component v-bind="modal.attrs" :is="modal.component" ref="modal" />
  </div>
</template>

<style lang="scss">
@use "@/styles/font-families";
@use "@/styles/global";
@use "@/styles/tooltip";
</style>
