<script lang="ts" setup>
import { computed } from "vue";

import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { cardKey } from "@/components/card/injectKeys";
import StickyLinkModal from "@/components/modal/stickyLink/StickyLinkModal.vue";
import { useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";
import { injectStrict } from "@/utils/vue";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";
import StickyLinks from "../StickyLinks/StickyLinks.vue";

const card = injectStrict(cardKey);

const links = computed(() => useLinkStore().getCardLinks(card));

const handleAddLinks = () => {
  return useModalStore().open(StickyLinkModal, {
    attrs: {
      cardIds: [card.id],
      "data-ignore-click": true,
      setReturnFocus: "#sticky-links-chip-trigger-" + card.id,
    },
  });
};
</script>

<template>
  <AttributeChip
    :id="'sticky-links-chip-trigger-' + card.id"
    name="links-chip"
    :dropdown-props="{ width: links.length ? 'xlg' : 'md', maxHeight: 'lg' }"
    text-placeholder="0"
    :text="links.length ? links.length.toString() : undefined"
    :tooltip="$t('general.links')"
  >
    <template #icon>
      <SvgIcon name="thin/link" />
    </template>
    <StickyLinks :links="links" @add="handleAddLinks" />
  </AttributeChip>
</template>
