<script lang="ts" setup>
import { computed } from "vue";

import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/vue";

import * as actions from "../../actions";
import { isActionVisible } from "../../utils";
import ActionMenuItem from "../ActionMenuItem/ActionMenuItem.vue";
import MoveSticky from "./MoveSticky.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const isVisible = computed(() => {
  const isVisible = isActionVisible("move", card, board.value);
  const isMovable = actions.isStickyMovable(card, board.value);

  return isVisible && isMovable;
});
</script>

<template>
  <ActionMenuItem
    v-if="isVisible"
    name="move"
    :tooltip-text="$t('cardAction.move')"
    :aria-label="$t('label.actionMenu.move')"
    aria-controls="move-sticky-dropdown"
  >
    <SvgIcon name="action-menu/move-to-board" width="20" height="20" />
    <template #dropdown>
      <MoveSticky id="move-sticky-dropdown" />
    </template>
  </ActionMenuItem>
</template>
