import { handleError } from "@/error/errorHandler";
import { captureMessage } from "@/error/sentry";
import { Tenant } from "@/model/session";
import AuthService from "@/services/auth.service";
import { neverResolve } from "@/utils/general";

export async function login(
  email: string,
  type = "",
  path = "",
  tenantId = "",
): Promise<Tenant[]> {
  let res;
  try {
    res = await AuthService.login({
      tenantId,
      email,
      type,
      path,
    });
  } catch (err: any) {
    const errorMessage = err.response?.data?.error;

    if (err.response?.status !== 401) {
      handleError(err, "Unexpected error while trying to log in");
    } else if (!isWrongEmail(errorMessage)) {
      void captureMessage(`Unauthorized to log in: ${errorMessage}`);
    }
    throw errorMessage;
  }

  if (!res.data.success) {
    const errorMessage = res.data.error;
    void captureMessage("Error while trying to log in", {
      response: { error: res.data.error },
    });
    throw errorMessage;
  }

  if (res.data.redirectUrl) {
    window.location.assign(res.data.redirectUrl);
    return neverResolve();
  }

  if (res.data.tenants) {
    return res.data.tenants;
  }

  throw Error("No data returned while trying to log in");
}

function isWrongEmail(errorMessage: string) {
  return errorMessage === "Invalid email" || errorMessage === "Invalid login";
}
